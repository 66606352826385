import { createI18n } from 'vue-i18n';
import messages from './messages';
import numberFormats from './numberFormats';

const locale = document.documentElement.lang.substring(0, 2);
window.currentLocale = locale;

const i18n = createI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages: messages,
    numberFormats: numberFormats,
});

export default i18n;

export function translate(key, ...args) {
    return key ? i18n.global.t(key, ...args) : '';
}

export function numberFormat(key, ...args) {
    return key ? i18n.global.n(key, ...args) : '';
}
