export default {
    en: {
        currency: {
            style: 'currency',
            currency: 'CAD',
            currencyDisplay: 'narrowSymbol',
        },
        currencyShort: {
            style: 'currency',
            currency: 'CAD',
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent',
            useGrouping: false,
        },
    },
    fr: {
        currency: {
            style: 'currency',
            currency: 'CAD',
            currencyDisplay: 'narrowSymbol',
        },
        currencyShort: {
            style: 'currency',
            currency: 'CAD',
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent',
            useGrouping: false,
        },
    },
};
