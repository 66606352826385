export default {
    "en": {
        "about_us": {
            "why_we_exist": {
                "title": "Why We Exist",
                "description": "Our purpose at Franchiseur is simple: make entrepreneurship accessible, innovative, and rewarding. We provide ambitious entrepreneurs the resources and flexibility they need to build businesses and make their mark.<br /><br />\nWith each proven brand known for delivering top-tier experiences, we offer more than products and services; we create lasting relationships with our customers and franchise partners, ensuring each franchisee has the support needed to grow sustainably in today’s fast-changing market.",
                "business_was_founded": "Business was founded",
                "people_on_the_team": "People on the team",
                "locations": "Locations",
                "yearly_revenue": "Yearly Revenue"
            },
            "our_history": {
                "title": "Our History"
            },
            "who_is_franchiseur": {
                "title": "Who is the Franchiseur",
                "description": "For over 12 years, Jim Cam, the visionary known as the Franchiseur, has been transforming industries and creating lasting business models. With over 100 locations in Canada, Jim built a franchise network of trusted and successful brands grounded in trust, diversity, innovation, and entrepreneurial empowerment.<br /><br />\nHis story is one of resilience, having taught himself through practical experience and an unyielding commitment to long-term growth and excellence, Jim’s leadership prioritizes franchisees’ success to thrive not only today but also for the challenges of tomorrow. With Franchiseur, Jim has established a lasting legacy that continues to shape the franchising world."
            },
            "our_values": {
                "title": "Our Values",
                "value_1": "Franchisee Empowerment & Success",
                "value_description_1": "We are committed to providing franchisees with the knowledge, tools, and freedom to thrive in today’s market. Their entrepreneurial success fuels our shared growth and excellence.",
                "value_2": "Flexibility & Growth",
                "value_description_2": "We embrace change and empower our franchisees to stay ahead of market trends and challenges in fast-evolving industries. Adaptability in our approach ensures both long-term viability and sustainable expansion.",
                "value_3": "Collaboration, Teamwork & Integrity",
                "value_description_3": "We cultivate a transparent, collaborative culture where franchisees, employees, and teams unite for shared success."
            }
        },
        "actions": {
            "accept": "Accept",
            "add": "Add",
            "agree": "Agree",
            "apply": "Apply",
            "back": "Back",
            "begin": "Begin",
            "by": "by",
            "cancel": "Cancel",
            "change": "Change",
            "checkout": "Checkout",
            "close": "Close",
            "collapse": "Collapse",
            "confirm": "Confirm",
            "confirmation": {
                "delete": "Are you sure you want to delete? This cannot be undone.",
                "submit": "Are you sure you want to submit?",
                "update": "Are you sure you want to update?"
            },
            "create": "Create",
            "decline": "Decline",
            "delete": "Delete",
            "disable": "Disable",
            "done": "Done",
            "download": "Download",
            "edit": "Edit",
            "enable": "Enable",
            "end": "End",
            "exit": "Exit",
            "expand": "Expand",
            "filter": "Filter",
            "find": "Find",
            "follow": "Follow",
            "go": "Go",
            "help": "Help",
            "hide": "Hide",
            "hide_all": "Hide All",
            "home": "Home",
            "less": "Less",
            "log_in": "Log In",
            "log_out": "Log Out",
            "manage": "Manage",
            "maximize": "Maximize",
            "minimize": "Minimize",
            "more": "More",
            "new": "New",
            "next": "Next",
            "no": "No",
            "no_results": "No results",
            "off": "Off",
            "okay": "Okay",
            "on": "On",
            "open": "Open",
            "other": "Other",
            "pay": "Pay",
            "previous": "Previous",
            "print": "Print",
            "publish": "Publish",
            "recommend": "Recommend",
            "redo": "Redo",
            "register": "Register",
            "remove": "Remove",
            "return": "Return",
            "save": "Save",
            "search": "Search",
            "select": "Select",
            "send": "Send",
            "show": "Show",
            "show_all": "Show All",
            "sign_up": "Sign Up",
            "social_login": "Continue with {provider}",
            "start": "Start",
            "stop": "Stop",
            "submit": "Submit",
            "submit_a_ticket": "Submit a ticket",
            "subscribe": "Subscribe",
            "undo": "Undo",
            "unsubscribe": "Unsubscribe",
            "update": "Update",
            "upload": "Upload",
            "view": "View",
            "view_all": "View All",
            "view_more": "View More",
            "yes": "Yes"
        },
        "address": {
            "address_line_1": "Address",
            "address_line_2": "Apartment, unit, etc.",
            "city": "City",
            "province": "Province",
            "state": "State",
            "postal_code": "Postal Code",
            "zip_code": "Zip Code",
            "country": "Country",
            "latitude": "Latitude",
            "longitude": "Longitude"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "Password",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "my_account": "My Account",
            "my_profile": "My Profile",
            "welcome": "Welcome, {name}",
            "login": "Log In",
            "register": "Sign Up",
            "logout": "Logout",
            "social_login": "Continue with {social_media}",
            "or": "or",
            "name": "Name",
            "first_name": "First Name",
            "last_name": "Last Name",
            "email": "Email Address",
            "email_or_phone": "Email Address or Phone Number",
            "confirm_password": "Confirm Password",
            "forgot_password": "Forgot password",
            "current_password": "Current Password",
            "new_password": "New Password",
            "phone_number": "Phone Number",
            "remember_me": "Remember me",
            "new_user": "Don't have an account?",
            "existing_user": "Already have an account?",
            "almost_there": "You're almost there!",
            "reset_password": "Reset Password",
            "verify": "Verify Phone Number",
            "otp": "Enter the 6-digit code sent to you.",
            "newsletter": "I want to join the VIP list for exclusive access to special promotions and discounts.",
            "terms_and_conditions": {
                "agree": "By checking this box, I confirm that I have read and agree to the",
                "and": "and"
            }
        },
        "brand": {
            "title": "Become a franchise owner of",
            "subtitle": "Create and develop your legacy with our proven and verified brands. Start your entrepreneurial adventure with us.",
            "cta": "This a sneak peek! We're now accepting applications!"
        },
        "franchise": {
            "title": "Become our partner",
            "information_request": "Information request",
            "information_request_description": "Please answer the following questions, which will help us determine if you are a qualified potential franchisee.",
            "form": {
                "referrer": "Referrer",
                "name": "Name",
                "date_of_birth": "Date of Birth",
                "phone_number": "Phone Number",
                "email": "Email",
                "interested_brands": "Which brands interest you the most?",
                "investments": "How much money are you willing to invest?",
                "area_of_interest": "In which location are you looking to open a franchise?",
                "address": "Address",
                "city": "City",
                "province": "Province",
                "postal_code": "Postal Code",
                "education": "Education",
                "work_experience": "Work experience",
                "current_income": "Current employment income",
                "other_income": "Other source of income",
                "specify_other_income": "If other income, please specify",
                "bank": "Financial institution(s) where you do business",
                "branch": "Branch",
                "criminal_record": "Have you ever been convicted of a crime?",
                "franchise_will_be_the_only_source_of_revenue": "Is your franchise your only source of income?",
                "total_assets": "Total assets",
                "total_debts": "Total debts",
                "minus": "Minus",
                "net_worth": "Net worth",
                "amount_available_for_investment": "Amount available for investment",
                "financing_source": "Source of financing for your franchise",
                "credit_rating": "Credit rating",
                "opening_date": "If your application is successful, when would you be ready to purchase your franchise?",
                "location": "Where you would like to operate your franchise",
                "operator": "Are you planning to operate your franchise yourself?",
                "if_not_operator": "If not, who will you entrust with the day-to-day management of your franchise?",
                "short_training": "Will you be available 2 weeks full time before the opening or takeover of your restaurant to be trained in an existing restaurant, even if you wish to entrust your day-to-day operations to someone else?",
                "long_training": "Will you be available 2 weeks full time before the opening or takeover of your restaurant to be trained in an existing restaurant, even if you wish to entrust your day-to-day operations to someone else?",
                "other_party": "Indicate, in the space provided, the names of any other persons who will be involved in the operation of your franchise. If any of these persons are required to sign the Franchise Agreement, have them complete a Request for Information form.",
                "yes": "Yes",
                "no": "No",
                "newsletter": "I agree to receive electronic communication from Franchiseur via e-mail and/or text message.",
                "terms": "I agree that the information submitted will be used for business development purposes. A representative may contact you via phone and/or email to discuss relevant opportunities. For more details, please refer to our Privacy Policy.",
                "submit": "Send Inquiry"
            },
            "success": "Your request has been sent!",
            "success_message": "Thank you for taking an interest in partnering with us. We appreciate the opportunity and look forward to exploring how we can work together."
        },
        "franchiseur": {
            "intro": {
                "subtitle": "Seize the Opportunity of a Lifetime",
                "title": "Empowering Success with Proven Franchising Systems",
                "description": "At Franchiseur, we offer a path to success through our thriving successful business models for entrepreneurs and business enthusiasts.<br /><br />\nCreate and develop your legacy with our proven and verified brands. Start your entrepreneurial adventure with us.",
                "cta": "Let's talk"
            },
            "portfolio": {
                "title": "Our Proven Portfolio of Performing Franchises",
                "description": "With a grand network of thriving franchises across multiple key locations, Franchiseur’s success shows the power of a proven, passion-driven model in diverse markets and industries.<br /><br />\nReady to join a top-performing franchise brand built for success?",
                "cta": "I'm Interested"
            },
            "steps": {
                "title": "Becoming a Franchise Owner",
                "step01": {
                    "title": "Application & Introduction",
                    "description": "Start your journey by filling out a quick online application form. We'll review your experience and background to ensure a strong match your the desired franchise."
                },
                "step02": {
                    "title": "Interview & Assessment",
                    "description": "Let's get in touch, discuss goals, motivations, and vision, and review your questions before conducting a financial assessment."
                },
                "step03": {
                    "title": "Approval, Discovery & Onboarding",
                    "description": "Once you’ve been given the green light, you'll sign the franchise agreement to join us. Our team will provide on-site training and integration programs to set you up for success."
                }
            }
        },
        "legal": {
            "privacy_policy": "Privacy Policy",
            "terms_of_service": "Terms of Service",
            "privacy": "Privacy",
            "terms": "Terms"
        },
        "location": {
            "business_hours": "Business Hours",
            "get_directions": "Get Directions",
            "closed": "Closed",
            "closing_soon": "Closing Soon",
            "open": "Open",
            "open_at": "Open {datetime}",
            "open_until": "Open until {datetime}",
            "view_all_locations": "View all locations",
            "store_info": "Store Info",
            "find_us": "Find Us",
            "find_location": "Find a Location",
            "location_search": "Search by address, postal code or city"
        },
        "navigation": {
            "about": "About",
            "franchising": "Franchising",
            "training": "Training",
            "partnership": "Partnership",
            "book_a_all": "Book a Call"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal to {value}.",
                "file": "The {attribute} must be greater than or equal to {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal to {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal to {value}.",
                "file": "The {attribute} must be less than or equal to {value} kilobytes.",
                "string": "The {attribute} must be less than or equal to {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "numeric": "The {attribute} must not be greater than {max}.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "string": "The {attribute} must not be greater than {max} characters.",
                "array": "The {attribute} must not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "phone": "The {attribute} field must be a valid number.",
            "present": "The {attribute} field must be present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "recaptcha": "reCAPTCHA validation failed. Please verify that you are not a robot and try again.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        }
    },
    "fr": {
        "about_us": {
            "why_we_exist": {
                "title": "Pourquoi Nous Existons",
                "description": "L'objectif de Franchiseur est simple : rendre l'entrepreneuriat accessible, innovant et gratifiant. Nous fournissons aux entrepreneurs ambitieux les ressources et la flexibilité dont ils ont besoin pour créer des entreprises et laisser leur marque.<br /><br />\nAvec chaque marque reconnue pour offrir des expériences de premier ordre, nous offrons plus que des produits et des services ; nous créons des relations durables avec nos clients et nos partenaires franchisés, en veillant à ce que chaque franchisé ait le soutien nécessaire pour se développer durablement dans le marché actuel en évolution rapide.",
                "business_was_founded": "L'entreprise a été fondée",
                "people_on_the_team": "Membres d'équipe",
                "locations": "Emplacements",
                "yearly_revenue": "Revenus Annuels"
            },
            "our_history": {
                "title": "Notre Histoire"
            },
            "who_is_franchiseur": {
                "title": "Qui est le Franchiseur",
                "description": "Depuis plus de 12 ans, Jim Cam, le visionnaire connu sous le nom de Franchiseur, transforme les industries et crée des modèles d'affaires durables. Avec plus de 100 établissements au Canada, Jim a créé un réseau de franchises de marques fiables et prospères fondées sur la confiance, la diversité, l'innovation et l'autonomisation des entrepreneurs.<br /><br />\nSon histoire est celle d'une résilience, qu'il a apprise par l'expérience pratique et par un engagement inflexible en faveur de la croissance et de l'excellence à long terme. Le leadership de Jim donne la priorité à la réussite des franchisés pour qu'ils prospèrent non seulement aujourd'hui, mais aussi pour relever les défis de demain. Avec Franchiseur, Jim a établi un héritage durable qui continue à façonner le monde "
            },
            "our_values": {
                "title": "Nos valeurs",
                "value_1": "Responsabilisation et réussite des franchisés",
                "value_description_1": "Nous nous engageons à fournir aux franchisés les connaissances, les outils et la liberté nécessaires pour prospérer sur le marché actuel. Leur réussite entrepreneuriale alimente notre croissance commune et notre excellence.",
                "value_2": "Flexibilité et croissance",
                "value_description_2": "Nous acceptons le changement et donnons à nos franchisés les moyens de rester à l'avant-garde des tendances et des défis du marché dans des secteurs qui évoluent rapidement. L'adaptabilité de notre approche garantit à la fois la viabilité à long terme et l'expansion durable.",
                "value_3": "Collaboration, travail d'équipe et intégrité",
                "value_description_3": "Nous cultivons une culture transparente et collaborative où les franchisés, les employés et les équipes s'unissent pour un succès partagé."
            }
        },
        "actions": {
            "accept": "Accepter",
            "add": "Ajouter",
            "agree": "Accepter",
            "apply": "Appliquer",
            "back": "Retour",
            "begin": "Début",
            "by": "par",
            "cancel": "Annuler",
            "change": "Modifier",
            "checkout": "Commander",
            "close": "Fermer",
            "collapse": "Réduire",
            "confirm": "Confirmer",
            "confirmation": {
                "delete": "Êtes-vous certains de vouloir supprimer? Cette action est permantente",
                "submit": "Confirmez la soumission?",
                "update": "Confirmez la mise à jour?"
            },
            "create": "Créer",
            "decline": "Refuser",
            "delete": "Supprimer",
            "disable": "Désactiver",
            "done": "Terminé",
            "download": "Télécharger",
            "edit": "Modifier",
            "enable": "Activer",
            "end": "Fin",
            "exit": "Quitter",
            "expand": "Étendre",
            "filter": "Filtrer",
            "find": "Trouver",
            "follow": "S'abonner",
            "go": "Aller",
            "help": "Aide",
            "hide": "Masquer",
            "hide_all": "Masquer tout",
            "home": "Accueil",
            "less": "Moins",
            "log_in": "Se connecter",
            "log_out": "Déconnecter",
            "manage": "Faire en sorte",
            "maximize": "Maximiser",
            "minimize": "Minimiser",
            "more": "Plus",
            "new": "Nouveau",
            "next": "Suivant",
            "no": "Non",
            "no_results": "Pas de résultats",
            "off": "Désactivé",
            "okay": "OK",
            "on": "Activé",
            "open": "Ouvrir",
            "other": "Autre",
            "pay": "Pay",
            "previous": "Précédent",
            "print": "Imprimer",
            "publish": "Publier",
            "recommend": "Recommander",
            "redo": "Rétablir",
            "register": "S'enregistrer",
            "remove": "Supprimer",
            "return": "Retour",
            "save": "Enregistrer",
            "search": "Rechercher",
            "select": "Sélectionner",
            "send": "Envoyer",
            "show": "Afficher",
            "show_all": "Afficher tout",
            "sign_up": "S'inscrire",
            "social_login": "Continuer avec {provider}",
            "start": "Début",
            "stop": "Arrêt",
            "submit": "Soumettre",
            "submit_a_ticket": "Soumettre un billet",
            "subscribe": "S'abonner",
            "undo": "Annuler",
            "unsubscribe": "Se désabonner",
            "update": "Mettre à jour",
            "upload": "Charger",
            "view": "Voir",
            "view_all": "Voir tout",
            "view_more": "Voir plus",
            "yes": "Oui"
        },
        "address": {
            "address_line_1": "Adresse",
            "address_line_2": "Appartement, unité, etc.",
            "city": "Ville",
            "province": "Province",
            "state": "État",
            "postal_code": "Code postal",
            "zip_code": "Code zip",
            "country": "Pays",
            "latitude": "Latitude",
            "longitude": "Longitude"
        },
        "auth": {
            "failed": "Ces identifiants ne correspondent pas à nos enregistrements.",
            "password": "Mot de passe",
            "throttle": "Tentatives de connexion trop nombreuses. Veuillez essayer de nouveau dans {seconds} secondes.",
            "my_account": "Mon compte",
            "my_profile": "Mon Profil",
            "welcome": "Bienvenue, {name}",
            "login": "Se connecter",
            "register": "S'inscrire",
            "logout": "Déconnexion",
            "social_login": "Continuer avec {social_media}",
            "or": "ou",
            "name": "Nom",
            "first_name": "Prénom",
            "last_name": "Nom",
            "email": "Adresse courriel",
            "email_or_phone": "Adresse courriel ou Numéro de téléphone",
            "confirm_password": "Confirmez le mot de passe",
            "forgot_password": "Mot de passe oublié",
            "current_password": "Mot de passe actuel",
            "new_password": "Nouveau mot de passe",
            "phone_number": "Numéro de téléphone",
            "remember_me": "Se souvenir de moi",
            "new_user": "Nouvel utilisateur?",
            "existing_user": "Vous avez déjà un compte?",
            "almost_there": "Vous y êtes presque!",
            "reset_password": "Réinitialiser le mot de passe",
            "verify": "Verify Phone Number",
            "otp": "Entrez le code à 6 chiffres qui vous a été envoyé.",
            "newsletter": "Je souhaite m'inscrire sur la liste VIP pour bénéficier d'un accès exclusif aux promotions et rabais.",
            "terms_and_conditions": {
                "agree": "En cochant cette case, je confirme que j'ai lu et que j'accepte les",
                "and": "et la"
            }
        },
        "brand": {
            "title": "Devenez propriétaire d'une franchise de",
            "subtitle": "Créez et développez votre héritage, c'est le moment pour vous de faire la différence. Commencez votre aventure entrepreneuriale avec nous.",
            "cta": "Ceci est un aperçu exclusif ! Nous acceptons maintenant les candidatures !"
        },
        "franchise": {
            "title": "Devenir notre partenaire",
            "information_request": "Demande d'informations",
            "information_request_description": "Veuillez répondre aux questions suivantes, qui nous aideront à déterminer si vous êtes un franchisé potentiel qualifié.",
            "form": {
                "referrer": "Référent",
                "name": "Nom",
                "date_of_birth": "Date de naissance",
                "phone_number": "Numéro de téléphone",
                "email": "Adresse courriel",
                "interested_brands": "Quelles sont les marques qui vous intéressent le plus ?",
                "investments": "Quelle somme êtes-vous prêt à investir ?",
                "area_of_interest": "Dans quelle région souhaitez-vous ouvrir une franchise ?",
                "address": "Adresse",
                "city": "Ville",
                "province": "Province",
                "postal_code": "Code Postal",
                "education": "Formation académique",
                "work_experience": "Expérience professionnelle",
                "current_income": "Revenu d’emploi actuel",
                "other_income": "Autres revenus",
                "specify_other_income": "Si autres revenus, spécifiez",
                "bank": "Institution(s) financière(s) où vous faites affaires",
                "branch": "Succursale",
                "criminal_record": "Avez-vous déjà été reconnu coupable d’un crime ?",
                "franchise_will_be_the_only_source_of_revenue": "Votre franchise représenterait-elle votre seule source de revenus ?",
                "total_assets": "Total de vos avoirs",
                "total_debts": "Total de vos dettes",
                "minus": "Moins",
                "net_worth": "Valeur nette",
                "amount_available_for_investment": "Somme disponible pour investir",
                "financing_source": "Source de financement pour votre franchise",
                "credit_rating": "Cote de crédit",
                "opening_date": "Si votre demande est acceptée, quand seriez-vous prêt à faire l’acquisition de votre franchise ?",
                "location": "Endroit où vous aimeriez exploiter votre franchise",
                "operator": "Comptez-vous opérer votre franchise vous-même ?",
                "if_not_operator": "Si non, à qui confierez-vous la gestion quotidienne de votre franchise ?",
                "short_training": "Serez-vous disponible 2 semaines à temps pleins avant l’ouverture ou la prise de possession de votre restaurant pour vous faire former dans un restaurant existant même si vous souhaitez confier vos opérations quotidiennes à une autre personne",
                "long_training": "Serez-vous disponible lors des 3 premiers mois pour l’ouverture du restaurant pour vous faire former, même si vous souhaitez confier vos opérations quotidiennes à une autre personne",
                "other_party": "Indiquez, dans l’espace prévu les noms de toute autre personne devant participer à l’exploitation de votre franchise. S’il advient que l’une ou l’autre de ces personnes est tenue de signer la Convention de franchise, faites-lui compléter une demande d’information.",
                "yes": "Oui",
                "no": "Non",
                "newsletter": "J'accepte de recevoir des communications électroniques de Franchiseur par e-mail et/ou par SMS.",
                "terms": "J'accepte que les informations fournies soient utilisées à des fins de développement commercial. Un représentant peut vous contacter par téléphone et/ou par courrier électronique pour discuter d'opportunités pertinentes. Pour plus de détails, veuillez consulter notre politique de confidentialité.",
                "submit": "Envoyer la demande"
            },
            "success": "Votre demande a été envoyée !",
            "success_message": "Nous vous remercions de l'intérêt que vous portez à notre partenariat. Nous apprécions l'opportunité qui nous est offerte et sommes impatients d'explorer les possibilités de collaboration."
        },
        "franchiseur": {
            "intro": {
                "subtitle": "Saisissez l'Opportunité d'une Vie",
                "title": "Favoriser la Réussite avec des Systèmes de Franchise Éprouvés",
                "description": "Chez Franchiseur, nous offrons une voie vers le succès grâce à nos méthodologies éprouvées, à un mentorat de premier ordre et à une formation complète. Nos modèles d'affaires florissants avec un historique de succès offrent des opportunités pour les entrepreneurs et les passionnés d'affaires de construire leur propre histoire et leur héritage avec nos marques éprouvées et vérifiées.<br /><br />\nCréez et développez votre héritage, c'est le moment pour vous de faire la différence. Commencez votre aventure entrepreneuriale avec nous.",
                "cta": "Discutons ensemble"
            },
            "portfolio": {
                "title": "Notre Portefeuille de Franchises Performantes",
                "description": "Avec un vaste réseau de franchises prospères réparties sur plusieurs endroits et territoires clés, le succès de Franchiseur démontre la puissance d'un modèle éprouvé et axé sur la passion dans divers marchés et secteurs d'activité.<br /><br />\nPrêt à rejoindre une marque de franchise performante et construite pour le succès ?",
                "cta": "Je suis intéressé"
            },
            "steps": {
                "title": "Devenir Propriétaire d'une Franchise",
                "step01": {
                    "title": "Candidature et introduction",
                    "description": "Commencez votre parcours en remplissant un rapide formulaire de candidature en ligne. Nous examinerons votre expérience et vos antécédents pour nous assurer qu'ils correspondent bien à la franchise souhaitée."
                },
                "step02": {
                    "title": "Rencontre et évaluation",
                    "description": "Nous prenons contact avec vous, discutons de vos objectifs, de vos motivations et de votre vision, et passons en revue vos questions avant de procéder à une évaluation financière."
                },
                "step03": {
                    "title": "Approbation, découverte et intégration",
                    "description": "Après avoir reçu le feu vert, vous signerez le contrat de franchise pour nous rejoindre. Notre équipe vous proposera une formation en cours d'emploi et des programmes d'intégration pour vous permettre de réussir."
                }
            }
        },
        "legal": {
            "privacy_policy": "Politique de confidentialité",
            "terms_of_service": "Conditions d'utilisation",
            "privacy": "Confidentialité",
            "terms": "Conditions d'utilisation"
        },
        "location": {
            "business_hours": "Heures d'ouverture",
            "get_directions": "Obtenir l'itinéraire",
            "closed": "Fermé",
            "closing_soon": "Bientôt fermé",
            "open": "Ouvert",
            "open_at": "Ouvert {datetime}",
            "open_until": "Ouvert jusqu'à {datetime}",
            "view_all_locations": "Voir tous les emplacements",
            "store_info": "Info sur l'emplacement",
            "find_us": "Nous trouver",
            "find_location": "Trouver un emplacement",
            "location_search": "Recherche par adresse, code postal ou ville"
        },
        "navigation": {
            "about": "À propos",
            "franchising": "Franchisage",
            "training": "Formation",
            "partnership": "Partenariat",
            "book_a_all": "Réserver un appel"
        },
        "pagination": {
            "previous": "&laquo; Précédent",
            "next": "Suivant &raquo;"
        },
        "passwords": {
            "reset": "Votre mot de passe a été réinitialisé!",
            "sent": "Nous vous avons envoyé par email le lien de réinitialisation du mot de passe!",
            "throttled": "Veuillez patienter avant de réessayer.",
            "token": "Ce jeton de réinitialisation du mot de passe n'est pas valide.",
            "user": "Aucun utilisateur n'a été trouvé avec cette adresse email."
        },
        "validation": {
            "accepted": "Le champ {attribute} doit être accepté.",
            "accepted_if": "Le champ {attribute} doit être accepté quand {other} a la valeur {value}.",
            "active_url": "Le champ {attribute} n'est pas une URL valide.",
            "after": "Le champ {attribute} doit être une date postérieure au {date}.",
            "after_or_equal": "Le champ {attribute} doit être une date postérieure ou égale au {date}.",
            "alpha": "Le champ {attribute} doit contenir uniquement des lettres.",
            "alpha_dash": "Le champ {attribute} doit contenir uniquement des lettres, des chiffres et des tirets.",
            "alpha_num": "Le champ {attribute} doit contenir uniquement des chiffres et des lettres.",
            "array": "Le champ {attribute} doit être un tableau.",
            "before": "Le champ {attribute} doit être une date antérieure au {date}.",
            "before_or_equal": "Le champ {attribute} doit être une date antérieure ou égale au {date}.",
            "between": {
                "array": "Le tableau {attribute} doit contenir entre {min} et {max} éléments.",
                "file": "La taille du fichier de {attribute} doit être comprise entre {min} et {max} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être comprise entre {min} et {max}.",
                "string": "Le texte {attribute} doit contenir entre {min} et {max} caractères."
            },
            "boolean": "Le champ {attribute} doit être vrai ou faux.",
            "confirmed": "Le champ de confirmation {attribute} ne correspond pas.",
            "current_password": "Le mot de passe est incorrect.",
            "date": "Le champ {attribute} n'est pas une date valide.",
            "date_equals": "Le champ {attribute} doit être une date égale à {date}.",
            "date_format": "Le champ {attribute} ne correspond pas au format {format}.",
            "declined": "Le champ {attribute} doit être décliné.",
            "declined_if": "Le champ {attribute} doit être décliné quand {other} a la valeur {value}.",
            "different": "Les champs {attribute} et {other} doivent être différents.",
            "digits": "Le champ {attribute} doit contenir {digits} chiffres.",
            "digits_between": "Le champ {attribute} doit contenir entre {min} et {max} chiffres.",
            "dimensions": "La taille de l'image {attribute} n'est pas conforme.",
            "distinct": "Le champ {attribute} a une valeur en double.",
            "email": "Le champ {attribute} doit être une adresse e-mail valide.",
            "ends_with": "Le champ {attribute} doit se terminer par une des valeurs suivantes : {values}",
            "enum": "Le champ {attribute} sélectionné est invalide.",
            "exists": "Le champ {attribute} sélectionné est invalide.",
            "file": "Le champ {attribute} doit être un fichier.",
            "filled": "Le champ {attribute} doit avoir une valeur.",
            "gt": {
                "array": "Le tableau {attribute} doit contenir plus de {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure à {value}.",
                "string": "Le texte {attribute} doit contenir plus de {value} caractères."
            },
            "gte": {
                "array": "Le tableau {attribute} doit contenir au moins {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure ou égale à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {value}.",
                "string": "Le texte {attribute} doit contenir au moins {value} caractères."
            },
            "image": "Le champ {attribute} doit être une image.",
            "in": "Le champ {attribute} est invalide.",
            "in_array": "Le champ {attribute} n'existe pas dans {other}.",
            "integer": "Le champ {attribute} doit être un entier.",
            "ip": "Le champ {attribute} doit être une adresse IP valide.",
            "ipv4": "Le champ {attribute} doit être une adresse IPv4 valide.",
            "ipv6": "Le champ {attribute} doit être une adresse IPv6 valide.",
            "json": "Le champ {attribute} doit être un document JSON valide.",
            "lt": {
                "array": "Le tableau {attribute} doit contenir moins de {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être inférieure à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être inférieure à {value}.",
                "string": "Le texte {attribute} doit contenir moins de {value} caractères."
            },
            "lte": {
                "array": "Le tableau {attribute} doit contenir au plus {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être inférieure ou égale à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être inférieure ou égale à {value}.",
                "string": "Le texte {attribute} doit contenir au plus {value} caractères."
            },
            "mac_address": "Le champ {attribute} doit être une adresse MAC valide.",
            "max": {
                "array": "Le tableau {attribute} ne peut contenir plus de {max} éléments.",
                "file": "La taille du fichier de {attribute} ne peut pas dépasser {max} kilo-octets.",
                "numeric": "La valeur de {attribute} ne peut être supérieure à {max}.",
                "string": "Le texte de {attribute} ne peut contenir plus de {max} caractères."
            },
            "mimes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "mimetypes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "min": {
                "array": "Le tableau {attribute} doit contenir au moins {min} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {min} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {min}.",
                "string": "Le texte {attribute} doit contenir au moins {min} caractères."
            },
            "multiple_of": "La valeur de {attribute} doit être un multiple de {value}",
            "not_in": "Le champ {attribute} sélectionné n'est pas valide.",
            "not_regex": "Le format du champ {attribute} n'est pas valide.",
            "numeric": "Le champ {attribute} doit contenir un nombre.",
            "password": "Le mot de passe est incorrect",
            "phone": "Le champ {attribute} doit être un numéro valide.",
            "present": "Le champ {attribute} doit être présent.",
            "prohibited": "Le champ {attribute} est interdit.",
            "prohibited_if": "Le champ {attribute} est interdit quand {other} a la valeur {value}.",
            "prohibited_unless": "Le champ {attribute} est interdit à moins que {other} est l'une des valeurs {values}.",
            "prohibits": "Le champ {attribute} interdit {other} d'être présent.",
            "recaptcha": "reCAPTCHA validation failed. Please verify that you are not a robot and try again.",
            "regex": "Le format du champ {attribute} est invalide.",
            "required": "Le champ {attribute} est obligatoire.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "Le champ {attribute} est obligatoire quand la valeur de {other} est {value}.",
            "required_unless": "Le champ {attribute} est obligatoire sauf si {other} est {values}.",
            "required_with": "Le champ {attribute} est obligatoire quand {values} est présent.",
            "required_with_all": "Le champ {attribute} est obligatoire quand {values} sont présents.",
            "required_without": "Le champ {attribute} est obligatoire quand {values} n'est pas présent.",
            "required_without_all": "Le champ {attribute} est requis quand aucun de {values} n'est présent.",
            "same": "Les champs {attribute} et {other} doivent être identiques.",
            "size": {
                "array": "Le tableau {attribute} doit contenir {size} éléments.",
                "file": "La taille du fichier de {attribute} doit être de {size} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être {size}.",
                "string": "Le texte de {attribute} doit contenir {size} caractères."
            },
            "starts_with": "Le champ {attribute} doit commencer avec une des valeurs suivantes : {values}",
            "string": "Le champ {attribute} doit être une chaîne de caractères.",
            "timezone": "Le champ {attribute} doit être un fuseau horaire valide.",
            "unique": "La valeur du champ {attribute} est déjà utilisée.",
            "uploaded": "Le fichier du champ {attribute} n'a pu être téléversé.",
            "url": "Le format de l'URL de {attribute} n'est pas valide.",
            "uuid": "Le champ {attribute} doit être un UUID valide",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        }
    }
}
